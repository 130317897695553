import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../components/layout/layout';
import Seo from '../components/utils/seo';
import ContentBlocks from '../components/homepage/contentBlocks';

const IndexPage = () => {
  const contentQuery = useStaticQuery(graphql`
  query {
    sanityHome {
      ...HomeContentBlocksFragment
      seo {
        title
        description
        image {
          asset {
            url
          }
        }
      }
    }  
  }
`);
  const title = contentQuery.sanityHome.seo.title
  const description = contentQuery.sanityHome.seo.description
  const image = contentQuery.sanityHome.seo.image.asset.url
  return (
    <Layout pageTitle="Home">
      <Seo title={title} description={description} image={image} />
      <ContentBlocks contentBlocks={contentQuery.sanityHome.contentBlocks} _rawContentBlocks={contentQuery.sanityHome._rawContentBlocks} />
    </Layout>
  )
}

export default IndexPage